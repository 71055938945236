import React from 'react';
import { homeCarousel } from '../../arrays/carouselArrays';
import Carousel from '../Carousel/Carousel';
import './inicio.css';

const Inicio = () => {
  return (
    <div>
        <Carousel name="carousel-home" data={homeCarousel} />
        <div className='container mt-5 ' >
            <div className='row justify-content-center main-title-section'>
                <div className='col-12 col-md-6'>
                    <h1>La cuna del golf en México</h1>
                    <p>Club de Golf Pachuca, anteriormente denominado "Pachuca Country Club", es la cuna que ve nacer el golf,
                        no sólo en el estado de Hidalgo, sino en todo el país.<br />
                        Activa tu membresía y sigue la tradición del amor al deporte aquí
                    </p>
                </div>
            </div>
            <div className='row campo-desc-row'>
                <div className='col-12'><h1 className='title-section'>Nuestro campo</h1></div>
                <div className='col-md-4 col-12 d-flex align-items-stretch'>
                    <div>
                        <p>Un recorrido de 7178 yardas para completar los 18 hoyos. 9 hoyos diseñados profesionalmente:</p>
                        <ul>
                            <li>Dos hoyos par 3 (los más cortos)</li>
                            <li>Cinco hoyos par 4</li>
                            <li>Y dos hoyos par 5 (los más largos)</li>
                        </ul>
                    </div>
                </div>
                <div className='col-md-4 col-12 d-flex align-items-stretch'>
                    <div className='campo-desc' style={{backgroundImage: "url(/img/campodesc.png)"}}></div>
                </div>
                <div className='col-md-4 col-12 d-flex align-items-stretch'>
                    <div>
                        <p>La presencia de lagos en todos los hoyos es un factor importante para determinar la dificultad del campo. El fuerte viento que sopla en «la bella airosa» es un elemento que dificulta el juego.</p>
                        <p>El Campo de Golf Pachuca es considerado «medianamente difícil», pero apto para niños, mujeres y hombres por igual, ya que a cada uno se le presenta un reto distinto.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Inicio
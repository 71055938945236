import React, { useEffect, useState } from 'react';
import "./carousel.css";

const Carousel = ({name, data }) => {
    const [dataCarousel, setDataCarousel] = useState([]);
    const nameCarousel = name;

    useEffect(()=>{
        setDataCarousel(data);
    },[]);

  return (
    <div id={nameCarousel} className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
            {
               dataCarousel.map((item, index) => (
                <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    style={{ backgroundImage: `url(${item.url})` }}
                    key={index}>
                </div>
               )) 
            }
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target={"#" + nameCarousel} data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target={"#" + nameCarousel} data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
  )
}

export default Carousel
import React from 'react';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <div id='footer' style={{backgroundImage: "url(/img/footer.png)"}}>
        <div className='overlay-footer'></div>
        <div className='container container-footer'>
          <div className='row'>
            <div className='col-12 col-md-6'>
                <div className='footer-info'>
                  <h3>Club de Golf Pachuca</h3>
                  <p>Copyrights© <span>{ date }</span> Todos los derechos reservados por Club de Golf Pachuca</p>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='footer-info'>
                  <p>Carr. Federal Mexico-Pachuca Km.85.5, Col, Venta Prieta, 42080 Pachuca de Soto, Hgo.</p>
                  <p>+52 77113077</p>
                  <div>
                    <a href='https://www.facebook.com/ClubdeGolfPachuca' target="_blank">
                      <FontAwesomeIcon className='footer-icon' icon={ faFacebookF }/>
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Footer
import React from 'react';
import './deleteuser.css';

const Deleteuser = () => {
  return (
    <div className='container container-deleteuser'>
        <div className='row'>
            <div className='col-12'>
                <h1>Borrar cuenta</h1>
                <p>Siga los siguientes pasos para borrar su cuenta en la App "Club de Golf Pachuca". Tome en cuenta que si elimina su cuenta ya no tendra acceso.</p>
            </div>
            <div className='col-12 col-md-6 mt-5'>
                <p>1.- Ingrese a la App e inicie sesión</p>
                <img className='deleteuser-image' src='/img/delete1.png' />
            </div>
            <div className='col-12 col-md-6 mt-5'>
                <p>2.- Dirijase a la sección administración en la parte inferior derecha</p>
                <img className='deleteuser-image' src='/img/delete2.png' />
            </div>
            <div className='col-12 col-md-6 mt-5'>
                <p>3.- Dirijase a la opción perfil en la parte superior izquierda</p>
                <img className='deleteuser-image' src='/img/delete3.png' />
            </div>
            <div className='col-12 col-md-6 mt-5'>
                <p>4.- De click en eliminar mi cuenta de forma definitiva </p>
                <img className='deleteuser-image' src='/img/delete4.png' />
            </div>
        </div>
    </div>
  )
}

export default Deleteuser
import Header from "./components/Header/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from "./components/Inicio/Inicio";
import TerminosCondiciones from "./components/TerminosCodiciones/TerminosCondiciones";
import Footer from "./components/Footer/Footer";
import Deleteuser from "./components/DeleteUser/Deleteuser";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
          <Routes>
            <Route exact path="/" element={ <Inicio /> } />
            <Route exact path="/legales" element={ <TerminosCondiciones /> } />
            <Route exact path="/borrarUsuario" element={ <Deleteuser /> } />
          </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

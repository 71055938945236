import React from 'react'

const TerminosCondiciones = () => {

  return (
    <div className='container mt-5'>
        <div className='row'>
            <div className='col-12'>
                <h1>Términos y Condiciones</h1>
                <p>
                    Estos Términos y Condiciones (los “Términos”) regulan el uso de la plataforma digital Club de Golf pachuca ofrecida por Club de Golf Pachuca S.A de C.V., conocida comercialmente como Club de Golf Pachuca, incluyendo la aplicación móvil Club de Golf Pachuca (la “App”), el sitio web www.clubgolfpachuca.com.mx y cualquier micrositio relacionado, además de los servicios ofrecidos por Club de Golf Pachuca (los “Servicios”). “Usted” se refiere a usted como usuario de la App, del Sitio y/o los Servicios. Estos Términos le aplican a Usted y a los usuarios de la App, del Sitio y/o Servicios, incluidos los visitantes del mismo.
                    POR FAVOR LEA ESTOS TÉRMINOS CUIDADOSAMENTE. Al usar la App, Sitio y/o contratar los Servicios o indicar de otro modo su aceptación (por ejemplo, al aceptar ser socio, miembro o iniciar sesión en su cuenta), Usted declara y garantiza que ha leído, comprende y acepta estar obligado por estos Términos. Si no está de acuerdo, no descargue la App, no utilice el Sitio, ni contrate los Servicios.
                    1.- Cuenta en la App y Sitio.
                        Al registrarse en la App o acceder al Sitio, Usted acepta proporcionar información verdadera, precisa, actual y completa. Usted acepta no registrarse en la App utilizando una identidad falsa o proporcionando información falsa o si previamente ha sido eliminado o se le ha prohibido el uso de la App. Usted es responsable de mantener la confidencialidad de la información de su cuenta. Usted es responsable de todas las actividades que ocurran en o con relación a su cuenta en la App y acepta notificar a Club de Golf Pachuca inmediatamente de cualquier acceso o uso no autorizado de su cuenta. Usted reconoce y acepta que CLub de Golf Pachuca no es responsable de ningún daño, pérdida, costo, gasto o responsabilidad relacionada con cualquier acceso no autorizado, trámite o uso de su cuenta en la App o Sitio.
                    2.- Elegibilidad.
                    Usted debe tener por lo menos 18 (dieciocho) años de edad para utilizar la App o los Servicios. Si Usted es menor de edad en México, su padre o tutor legal deben estar de acuerdo con estos Términos en su nombre y que sólo pueden acceder y utilizar la App y contratar los Servicios con el permiso de su padre o tutor legal.
                    Usted declara y garantiza que: i) no se le ha prohibido usar o acceder a la App por Club de Golf Pachuca o por cualquier ley o regulación aplicable; y ii) cumplirá con todos los términos aplicables.
                </p>
                <h1>Políticas de Privacidad</h1>
                <p>
                    Club de Golf Pachuca S.A. de C.V., por este medio y a fin de dar cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”), avisa a los usuarios, entiéndanse clientes finales, empresas o representantes de empresas que ingresen al sitio web y/o micrositios (en adelante el “Usuario”), que los datos personales brindados por el Usuario, proporcionados por cualquier medio, con motivo de la contratación o prestación de un servicio o colaboración que se realice con Club de Golf Pachuca, que este garantiza al Usuario la privacidad y el derecho a la autodeterminación informativa en el uso y manejo de los datos personales que requerimos nos proporcione. Toda la información del Usuario será protegida y salvaguardada bajo los más altos estándares de seguridad tecnológicos con los que cuenta Club de Golf Pachuca.
                </p>
            </div>
        </div>
    </div>
  )
}

export default TerminosCondiciones